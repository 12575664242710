import React, {Component} from 'react'
import {gql} from 'graphql.macro'

import {Input} from 'Components/Form'
import axios from 'utils/getAxios'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import Modal from 'Components/Modal'
import './index.scss'

const UPDATE_MASS_SETTING = gql`
  mutation UPDATE_MASS_SETTING(
    $deviceIDs: String!
    $userIDs: String!
    $selectedTag: String!
    $setting1: String!
    $setting2: String!
    $setting3: String!
    $setting4: String!
    $setting5: String!
    $setting6: String!
    $setting7: String!
  ) {
    updateMassSetting(deviceIDs: $deviceIDs, userIDs: $userIDs, selectedTag: $selectedTag, setting1: $setting1, setting2: $setting2, setting3: $setting3, setting4: $setting4, setting5: $setting5, setting6: $setting6, setting7: $setting7) {
     success
    }
  }
`

const CLEAR_DEVICE_DATA = gql`
  mutation CLEAR_DEVICE_DATA($deviceID: Int!) {
    clearDeviceData(deviceID: $deviceID) {
      success
    }
  }
`

const GET_COMPANIES = gql`
  query GET_COMPANIES {
    me {
      id
      isSuperUser
    }
    companies {
      id
      name
    }
  }
`

class NewSetting extends Component {
  state = {
    deviceIDs: [],
    deviceIDsPlaceholder: '',
    userIDs: [],
    userIDsPlaceholder: '',
    tag: '',
    deviceData: null,
    action: '',
    path: '',
    companyID: '',
    intervalReading: null,
    intervalTransmit: null,
    applicationImageSignature: null,
    relocateImageSignature: null,
    applicationFirmwareVersions: [],
    relocateFirmwareVersions: [],
    clearDataWarning: false,
    loading: false,
    successMessage: '',
    tagOptions: [{label: 'pressure', value: 'psr'},{label: 'tank', value: 'spsr'},
      {label: 'temperature', value: 'temp'},{label: 'vibration', value: 'vib'},
      {label: 'switch', value: 'swt'}]
  }
  resetState = () =>
    this.setState({
      deviceIDs: [],
      deviceIDsPlaceholder: '',
      userIDs: [],
      userIDsPlaceholder: '',
      tag: '',
      deviceData: null,
      path: '',
      companyID: '',
      intervalReading: null,
      intervalTransmit: null,
      applicationImageSignature: null,
      relocateImageSignature: null,
      applicationFirmwareVersions: this.state.applicationFirmwareVersions,
      relocateFirmwareVersions: this.state.relocateFirmwareVersions,
      clearDataWarning: false
    })

  async componentDidMount() {
    try {
      const {data} = await axios.get('/api/fota/firmware-versions')

      let applicationFirmwareVersions = data.filter(
        ({name}) =>
          name.toLowerCase().includes('application') ||
          name.toLowerCase().includes('generic')
      )
      let relocateFirmwareVersions = data.filter(({name}) =>
        name.toLowerCase().includes('relocate')
      )

      // console.log(applicationFirmwareVersions)
      // console.log(relocateFirmwareVersions)
      
      applicationFirmwareVersions.push({name: "NULL" ,crc_id: '32762'})
      relocateFirmwareVersions.push({name: "NULL", crc_id: '32762'})

      this.setState({applicationFirmwareVersions})
      this.setState({relocateFirmwareVersions})
    } catch (err) {
      console.error('Error fetching firmware versions')
    }
  }

  // handleDeviceIDChange = (e) => {
  //   const input = e.target.value
  //   const deviceIDs = input
  //     .split(',')
  //     .map((id) => id.trim())
  //     .filter((id) => !isNaN(id) && id !== '')
  //     .map((id) => parseInt(id, 10))

  //   this.setState({deviceIDs})
  // }

  // handleDataChange = (data) => {
  //   if (data && data.device) {
  //     this.setState({
  //       deviceData: data.device
  //     })
  //   }
  // }
  onChange = (e) => {
    const key = e.target.dataset && e.target.dataset.key
    let value = e.target.value
    // if (key === 'apiNumber' && isNaN(value)) {
    //   value = null
    // }
    if (!this.state.hasOwnProperty(key)) return
    this.setState({[key]: value})
  }

  submitForm = async () => {
    try {
      this.setState({loading: true})
      const deviceIDs = this.state.deviceIDsPlaceholder
      this.setState({deviceIDs})
      const userIDs = this.state.userIDsPlaceholder
      this.setState({userIDs})

      let selectedTag = '', setting1 = '', setting2 = '', setting3 = '', setting4 = '', setting5 = '', setting6 = '', setting7 = ''
      if (this.state.tag !== null && this.state.tag !== 'None') {
        selectedTag = this.state.tag
      }
      if (
            this.state.applicationImageSignature !== null &&
            this.state.applicationImageSignature !== 'Select signature'
        ) {
          setting1 = this.state.applicationImageSignature
        }     
      if (
          this.state.relocateImageSignature !== null &&
          this.state.relocateImageSignature !== 'Select signature'
        ) { 
          setting2 = this.state.relocateImageSignature
        }
      if (this.state.path !== '') { 
          setting3 = this.state.path
        }      
      if (this.state.companyID !== null && this.state.companyID !== '') {
        setting4 = this.state.companyID
      }
      if (this.state.intervalReading !== null && this.state.intervalReading !== null) {
        setting5 = this.state.intervalReading
      }
      if (this.state.intervalTransmit !== null && this.state.intervalTransmit !== null) {
        setting6 = this.state.intervalTransmit    
      }
      if (this.state.action !== null && this.state.action !== 'none') {
        setting7 = this.state.action
      }
      await this.props.updateMassSetting({
        variables: {
          deviceIDs: deviceIDs,
          userIDs: userIDs,
          selectedTag: selectedTag.toString(),
          setting1: setting1.toString(),
          setting2: setting2.toString(),
          setting3: setting3.toString(),
          setting4: setting4.toString(),
          setting5: setting5.toString(),
          setting6: setting6.toString(),
          setting7: setting7.toString(),
        }
      })
    } catch (error) {
      console.error('Error saving changes: ', error)
    } finally {
      this.setState({loading: false})
      this.resetState()
      this.displaySuccessMessage();
    }
  }

  clearData = async () => {
    this.setState({
      clearDataWarning: false,
      loading: true
    })
    const deviceIDs = this.state.deviceIDsPlaceholder
      .split(',')
      .map((id) => id.trim())
      .filter((id) => !isNaN(id) && id !== '')
      .map((id) => parseInt(id, 10))

    this.setState({deviceIDs})

    try {
      await Promise.all(
        deviceIDs.map(async (id) => {
          await this.props.clearDeviceData({
            variables: {deviceID: id}
          })
        })
      )
      if (
        this.state.applicationImageSignature !== null ||
        this.state.relocateImageSignature !== null ||
        this.state.path !== null
      ) {
        await this.submitForm()
      }
    } catch (error) {
      this.displayErrorMessage();
    }
    // window.location.reload()
    this.setState({loading: false})
    this.resetState()
    this.displaySuccessMessage();
  }

  displaySuccessMessage = () => {
    this.setState({successMessage: 'Update successfully.'});
    setTimeout(() => {
      this.setState({successMessage: ''});
    }, 3000);
  }

  render() {
    return (
      <div className='container'>
        <div className="left-side">
          <label className="device-id-label">Device ID(s): </label>
          <input
            type="text"
            name="deviceID"
            value={this.state.deviceIDsPlaceholder}
            onChange={(e) =>
              this.setState({deviceIDsPlaceholder: e.target.value})
            }
            className="device-id-input"
          />
          <label className="device-id-label">User ID(s) *Optional*: </label>
          <input
            type="text"
            name="userID"
            value={this.state.userIDsPlaceholder}
            onChange={(e) =>
              this.setState({userIDsPlaceholder: e.target.value})
            }
            className="device-id-input"
          />
        </div>
        <div className='right-side'>
          <Input
            id="device-path-input"
            type="directory"
            label="Directory Path"
            onChange={(e) => this.setState({path: e.target.value})}
            data-key="path"
            value={this.state.path}
            static={false}
          />
          <Input
            type="select"
            label="Company"
            onChange={this.onChange}
            data-key="companyID"
            value={this.state.companyID}
            static={false}
            options={[{label: 'None', value: null}].concat(
              ((this?.props?.companies?.companies) || [])
              .map((c) => ({
                label: c.name,
                value: c.id
              }))
            )}
          />
          <Input
            type="number"
            label="Reading Interval (minutes)"
            onChange={this.onChange}
            data-key="intervalReading"
            value={this.state.intervalReading}
            static={false}
          />
          <Input
            type="number"
            label="Transmit Interval (minutes)"
            onChange={this.onChange}
            data-key="intervalTransmit"
            value={this.state.intervalTransmit}
            static={false}
          />
          <div>
            <label>Application Image Signature</label>
            <select
              value={this.state.applicationImageSignature || ''}
              onChange={(e) => {
                this.setState({applicationImageSignature: e.target.value})
              }}
            >
              <option key="1">Select signature</option>
              {this.state.applicationFirmwareVersions.map(({crc_id}) => (
                <option key={crc_id} value={crc_id}>
                  {crc_id}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Relocate Image Signature</label>
            <select
              value={this.state.relocateImageSignature || ''}
              onChange={(e) => {
                this.setState({relocateImageSignature: e.target.value})
              }}
            >
              <option key="1">Select signature</option>
              {this.state.relocateFirmwareVersions.map(({crc_id}) => (
                <option key={crc_id} value={crc_id}>
                  {crc_id}
                </option>
              ))}
            </select>
          </div>
          <div className='alarms'>
            <Input
            type="select"
            label="Alarm Notification Options (ONLY IF USERIDS PROVIDED)"
            onChange={this.onChange}
            data-key="action"
            value={this.state.action}
            static={false}
            options={[{label: 'None', value: null}, {label: 'SMS', value: 'sms'}, {label: 'Email', value: 'email'}]}
            />

            <Input
            type="select"
            label="Alarm Tag"
            onChange={this.onChange}
            data-key="tag"
            value={this.state.tag}
            static={false}
            options={[{label: 'None', value: null}].concat(
              ((this.state.tagOptions) || [])
              .map((t) => ({
                label: t.label,
                value: t.value
              }))
            )}
          />
          </div>
          <div className="button-container">
            <button
              className="red primary"
              onClick={() => this.setState({clearDataWarning: true})}
              style={{margin: '5px'}}
            >
              Clear Data
            </button>{' '}
            <button className="primary" onClick={this.submitForm}>
              Save Changes
            </button>
          </div>

          {this.state.loading && (
            <div className="loading-indicator">Loading...</div>
          )}
          {this.state.successMessage && (
            <div className="success-message">{this.state.successMessage}</div>
          )}
        </div>
        {this.state.clearDataWarning && (
          <Modal onClose={() => this.setState({clearDataWarning: false})}>
            <Modal.Title>Confirm Before Continuing</Modal.Title>
            <Modal.Body>
              Are you sure that you want to clear all tag data for this device?
            </Modal.Body>
            <Modal.Footer>
              <button onClick={() => this.setState({clearDataWarning: false})}>
                Cancel
              </button>
              <button onClick={this.clearData} className="red primary">
                Yes, Clear All Data
              </button>
            </Modal.Footer>
          </Modal>
        )}{' '}
      </div>
    )
  }
}

export default compose(
  graphql(UPDATE_MASS_SETTING, {name: 'updateMassSetting'}),
  graphql(CLEAR_DEVICE_DATA, {name: 'clearDeviceData'}),
  graphql(GET_COMPANIES, {name: 'companies'})
)(NewSetting)
